/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "marsrest",
            "endpoint": "https://zwe1ax0rci.execute-api.ap-northeast-2.amazonaws.com/dev",
            "region": "ap-northeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nhca6et5pza6tcqth4kwhuo6jm.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sgot2ntpprbillccyqudzerdpi",
    "aws_cognito_identity_pool_id": "ap-northeast-2:a664482d-ab99-4ddb-b687-d3c6e6b6ef77",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_RGChKvnr6",
    "aws_user_pools_web_client_id": "6kdik13fst68jvqp755bvkgiu6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "marsstorage144618-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
